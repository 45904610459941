@import 'Fonts.css';
@import 'Colors.css';
@import 'Typography.css';
@import 'Spacing.css';

body {
  background-color: rgb(230, 230, 230);
  font-family: 'Times New Roman', Times, serif;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: var(--INDIGO_500_HOVERED);
}

.oone-clickable {
  cursor: pointer;
}

.oone-hover:hover,
.oone-hover a:hover,
.oone-hover:hover svg path {
  fill: var(--MAIN_GREEN) !important;
  color: var(--MAIN_GREEN) !important;
}

input:focus-visible {
  outline: none;
}

.mobile {
  display: none !important;
}

.desktop {
  display: flex !important;
}

hr {
  color: var(--GRAY_100);
  opacity: 1;
  margin: 0;
}

.oone-row {
  margin-bottom: 5%;
  display: flex;
  gap: 3%;
  justify-content: space-between;
}

.oone-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid #f2f2f3;
  border-radius: 1em;
  background-color: white;
  padding: 2rem 3rem;
}

.oone-card > * {
  width: 100%;
}

.oone-card-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3% 3%;
}

.oone-adcard-header-text {
  font-family: 'Sohne-Bold';
  font-style: normal;
  font-weight: 700;
  font-size: max(0.9em, 1vw);
  line-height: 1.875em;
  color: var(--GRAY_900);
}

.oone-card-details-text {
  font-family: 'Sohne-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--GRAY_700);
}

.oone-card-header-buttons {
  color: red;
}

.oone-card-table-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1vw;
  padding: 3% 0;
}

.oone-card-table-wrapper hr:last-of-type {
  display: none;
}

.oone-card-body-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2% 0;
}

.oone-card-body-row {
  display: flex;
  justify-content: space-between;
  padding: 1.5% 3%;
}

.oone-card-body-values-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.oone-navbar {
  padding: 14px 28px 14px 28px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  width: 90vw;
  background-color: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  margin: auto;
  margin-top: 30px;
}

.oone-services-bar {
  padding: 1% 7% 0 7%;
  height: 3rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.width-175 {
  width: 12% !important;
  justify-content: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.status-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 3% 0 3%;
}

.hash-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
}

/* tippy styles */
.tippy-box[data-theme~='oone-tooltip'] {
  border-radius: 0.5rem;
  background-color: var(--GRAY_900);
  color: var(--BASE-WHITE);
  /* Font styles matches .txt-xs-semibold */
  font-family: 'Sohne-SemiBold';
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 150%;
  margin: 0;
  padding: 0.2rem 0.3rem;
  text-align: center;
}

.no-wrap {
  white-space: nowrap;
}

.no-break-out {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
}
