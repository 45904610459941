/* ---------------------------------------------------------------------------------
    MARGINS TOP
--------------------------------------------------------------------------------- */

/* 12 px */
.mt-12 {
  margin-top: 0.75rem;
}

/* 16 px */
.mt-16 {
  margin-top: 1rem;
}

/* 18 px */
.mt-18 {
  margin-top: 1.125rem;
}

/* 20 px */
.mt-20 {
  margin-top: 1.25rem;
}

/* 22 px */
.mt-22 {
  margin-top: 1.375rem;
}

/* 24 px */
.mt-24 {
  margin-top: 1.5rem;
}

/* 28 px */
.mt-28 {
  margin-top: 1.75rem;
}

/* 40 px */
.mt-40 {
  margin-top: 2.5rem;
}

/* 48 px */
.mt-48 {
  margin-top: 3rem;
}

/* 52 px */
.mt-52 {
  margin-top: 3.25rem;
}

/* 92 px */
.mt-92 {
  margin-top: 5.75rem;
}

/* 120 px */
.mt-120 {
  margin-top: 7.5rem;
}

/* 140 px */
.mt-140 {
  margin-top: 8.75rem;
}

/* 160 px */
.mt-160 {
  margin-top: 10rem;
}

/* ---------------------------------------------------------------------------------
    MARGINS BOTTOM
--------------------------------------------------------------------------------- */

/* 12 px */
.mb-12 {
  margin-bottom: 0.75rem;
}

/* 16 px */
.mb-16 {
  margin-bottom: 1rem;
}

/* 18 px */
.mb-18 {
  margin-bottom: 1.125rem;
}

/* 20 px */
.mb-20 {
  margin-bottom: 1.25rem;
}

/* 22 px */
.mb-22 {
  margin-bottom: 1.375rem;
}

/* 24 px */
.mb-24 {
  margin-bottom: 1.5rem;
}

/* 28 px */
.mb-28 {
  margin-bottom: 1.75rem;
}

/* 40 px */
.mb-40 {
  margin-bottom: 2.5rem;
}

/* 52 px */
.mb-52 {
  margin-bottom: 3.25rem;
}

/* 92 px */
.mb-92 {
  margin-bottom: 5.75rem;
}

/* 120 px */
.mb-120 {
  margin-bottom: 7.5rem;
}
